@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@700&display=swap');

// 1. Your custom variables and variable overwrites.
@import "theme/variables-theme";

// 2. Import default variables and available mixins.
@import "uikit/src/scss/variables-theme.scss";
@import "uikit/src/scss/mixins-theme.scss";

// 3. Your custom mixin overwrites.
//@mixin hook-card() { color: #000; }

// 4. Import UIkit.
@import "uikit/src/scss/uikit-theme.scss";

@import "theme/custom";

