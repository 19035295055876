html {
  background-color: #c45a5a;
}

.ema-title-container {
  .ema-title {
    font-family: 'Courier Prime', monospace;
    font-size: 10rem;
    text-shadow: -1px -1px 0px $inverse-global-color,
    1px 1px 0px $inverse-global-color;
  }

  .ema-title-sub {

  }
}

.ema-grid-center {
  justify-content: center;;
}